const youtubeEmbedOptions = '?controls=1&modestbranding=1&rel=0';

import { defineStore } from 'pinia';
import { AUTHORS } from './authors';
import { JOBS } from './jobs';
import { TAGS } from './tags';
import { CONFERENCE_TALKS } from './talks';
import { TEAM } from './team';

export const blogPosts = [
  // **** Add newest Blogs here *****
  {
    // MUST BE INCREMENTED !!!!!
    id: 34,
    pageTitle: 'Announcing NestJS 11: What’s New',
    titleEnd: '?',
    subTitle: 'Announcing NestJS 11: What’s New?',
    description:
      'Today I am excited to announce the official release of Nest 11: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/announcing-nestjs-11-whats-new`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'nestjs-v11-is-now-available.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'January 22, 2025',
    publishedTime: '2025-01-22T17:37:28.745Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    id: 33,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'NestJS Metadata Deep Dive',
    description:
      "In this article we'll be doing a deep-dive and learning about how NestJS uses Metadata internally for everything from dependency injection, to decorators we use everyday!",
    // This is literally the name of your blog/{file-name-is-your-url}.vue
    link: `/blog/nestjs-metadata-deep-dive`,
    imageName: 'nestjs-metadata-deep-dive.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Documentation], // we can use these later
    date: 'July 18, 2023',
    publishedTime: '2023-07-18T14:41:05.237Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 8,
    author: AUTHORS.JAY_MCDONIEL,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 32,
    pageTitle: 'NestJS v10 is now available',
    titleEnd: '!',
    subTitle: 'NestJS v10 is now available 🍷',
    description:
      'Today I am excited to announce the official release of Nest 10: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/nestjs-10-is-now-available`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'nestjs-v10-is-now-available.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'June 19, 2023',
    publishedTime: '2023-06-19T13:20:55.585Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 31,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Implementing data source agnostic services with NestJS',
    description:
      'Learn how to implement data source logic in an agnostic way in yours NestJS applications.',
    link: '/blog/implementing-data-source-agnostic-services-with-nestjs',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Patterns],
    imageName: 'implementing-data-source-agnostic-services-with-nestjs.png',
    date: 'April 14, 2023',
    publishedTime: '2023-04-14T13:07:37.970Z',
    author: AUTHORS.MANUEL_CARBALLIDO,
    readLength: 12,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 30,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'NestJS Websockets Messages and RxJS Subjects',
    description:
      'Learn how to send dynamic events from the server using RxJS Subjects and Websockets',
    link: '/blog/nestjs-websockets-with-subjects',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.ReactiveProgramming, TAGS.Patterns],
    imageName: 'nestjs-websockets-rxjs.png',
    date: 'April 4, 2023',
    publishedTime: '2023-04-04T14:09:08.847Z',
    author: AUTHORS.JAY_MCDONIEL,
    readLength: 15,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 29,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Advanced Topic - Decorators, Metadata, and How Nest Uses Them',
    description:
      'A discussion about what the @Injectable() decorator is doing and how Nest uses it',
    // This is literally the name of your blog/{file-name-is-your-url}.vue
    link: `/blog/advancecd-concepts-dedcorators-and-metadata`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: '',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Documentation], // we can use these later
    date: '',
    publishedTime: '',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 20,
    author: AUTHORS.JAY_MCDONIEL,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 28,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Test Driven Development with NestJS - Integration Tests',
    titleEnd: '(Part 2)',
    subTitle: '',
    description:
      "In this 2nd part of the series on Test Driven Development. Let's learn how use TDD in our Integration tests in NestJS.",
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/tdd-with-nestjs-integration-tests`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'tdd-in-nestjs-integration-tests.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Tests, TAGS.TDD], // we can use these later
    date: 'March 2, 2023',
    publishedTime: '2023-03-02T15:00:48.506Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 20,
    author: AUTHORS.THIAGO_MARTINS,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 27,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Applying Test Driven Development with NestJS',
    titleEnd: '(Part 1)',
    subTitle: 'using unit tests',
    description:
      "TDD is a powerful discipline that allows us to create reliable applications using tests to drive its implementation. Let's learn how to do it using NestJS.",
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/tdd-with-nestjs`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'tdd-in-nestjs.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Tests, TAGS.TDD], // we can use these later
    date: 'January 24, 2023',
    publishedTime: '2023-01-24T04:04:56.752Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2023-01-24T16:24:12.642Z',
    readLength: 15,
    author: AUTHORS.THIAGO_MARTINS,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 26,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Circular Dependencies in NestJS and how to Avoid Them',
    titleEnd: '',
    subTitle: '',
    description:
      'A deep dive on Circular Dependencies with NestJS and tips for how to find and avoid them',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: '/blog/avoiding-circular-dependencies-in-nestjs',
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'circular-dependencies-in-nestjs.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'December 15, 2022',
    publishedTime: '2022-12-15T14:42:58.407Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 5,
    author: AUTHORS.JAY_MCDONIEL,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 25,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Swagger API Documentation Tips and Tricks',
    titleEnd: 'in NestJS',
    subTitle: '@nestjs/swagger useful tricks',
    description:
      "The main goal of any good API documentation is to transfer the information from creator to the users on how to use everything. Let's learn how we can improve our NestJS Swagger APIs to keep everything short, concise, but at the same time provide valuable details!",
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/nestjs-swagger-tips-tricks`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'nestjs-swagger-tips-tricks.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Swagger, TAGS.Documentation], // we can use these later
    date: 'November 8, 2022',
    publishedTime: '2022-11-08T14:44:46.660Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-11-10T14:28:48.295Z',
    readLength: 10,
    author: AUTHORS.ILYA_MOROZ,
  },
  {
    id: 24,
    pageTitle: 'Fundamentals of Automated Testing - Unit Tests Basic Concepts',
    titleEnd: '(part 1)',
    subTitle: 'Exploring best practices for unit testing applications',
    description:
      "This is the first article in a series to understand the fundamentals of automated testing with NestJS. In this part we're going to understand the basic concepts and components of unit testing in general.",
    link: `/blog/fundamentals-of-automated-testing-unit-tests-basic-concepts`,
    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'fundamentals-of-testing-unit-tests-basic-concepts.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Tests],
    date: 'October 24, 2022',
    // mark to do this when publishing
    publishedTime: '2022-10-24T13:55:26.580Z',
    readLength: 9, // How long roughly is the article?
    author: AUTHORS.DANIEL_DE_LUCCA,
  },
  {
    // MUST BE INCREMENTED !!!!
    id: 23,
    // MUST BE INCREMENTED !!!!
    pageTitle: 'NestJS Authentication without Passport',
    titleEnd: '',
    description:
      'Create a local login system with username and password, and persist the login using JWTs, all without the use of PassportJS',
    link: '/blog/nestjs-authentication-without-passport',
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'nestjs-authentication-without-passport.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Auth], // we can use these later
    date: 'September 7, 2022',
    publishedTime: '2022-09-07T13:33:36.301Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '',
    readLength: 10,
    author: AUTHORS.JAY_MCDONIEL,
    showAuthCourseSection: true,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 22,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Advanced Testing Strategies with Mocks',
    titleEnd: 'in NestJS',
    subTitle:
      'How to use mock strategies to help tests hard-to-setup dependencies/providers.',
    description:
      "Creating unit tests requires mocks or stubs to serve as proxy for some dependencies. In some cases, these dependencies are not so easily replaceable because they have many methods and/or objects as parameters. Let's learn some strategies to easily mock them.",
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/advanced-testing-strategies-with-mocks-in-nestjs`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'advanced-testing-in-nestjs.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Tests, TAGS.Mocks], // we can use these later
    date: 'August 11, 2022',
    publishedTime: '2022-08-11T14:00:23.142Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 10,
    author: AUTHORS.THIAGO_MARTINS,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 21,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Dependency Inversion Principle',
    titleEnd: 'with NestJS',
    subTitle: 'Dependency Inversion Principle with NestJS',
    description:
      "This principle from SOLID is the most dependent on our Dependency Injection system. Let's look at how it works with NestJS!",
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/dependency-inversion-principle`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'dependency-inversion-principle-with-nestjs.png',
    tags: [TAGS.NestJS, TAGS.Patterns, TAGS.Productivity], // we can use these later
    date: 'August 1, 2022',
    publishedTime: '2022-08-01T13:11:07.087Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 8,
    author: AUTHORS.MACIEJ_SIKORSKI,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 20,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'NestJS v9 is now available',
    titleEnd: '!',
    subTitle: 'NestJS v9 is now available 🍷',
    description:
      'Today I am excited to announce the official release of Nest 9: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/nestjs-9-is-now-available`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'nestjs-9-is-now-available.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'July 8, 2022',
    publishedTime: '2022-07-08T11:37:56.022Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
    disableCourseSection: true,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 19,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'What is the NestJS Runtime',
    titleEnd: '?',
    subTitle: 'What is the NestJS Runtime?',
    description: `Let's look at how our NestJS applications tie everything together under the hood, and understand some common misconceptions with dependency injection, scopes, and much more.`,
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/what-is-the-nestjs-runtime`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'what-is-the-nestjs-runtime.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'June 23, 2022',
    publishedTime: '2022-06-23T18:59:06.469Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 6,
    author: AUTHORS.MANUEL_HERRERA,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 18,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Announcing NestJS 8: What’s New',
    titleEnd: '?',
    subTitle: 'Announcing NestJS 8: What’s New? 🍷',
    description:
      'Today I am excited to announce the official release of Nest 8: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/announcing-nestjs-8-whats-new`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'announcing-nestjs-8--whats-new.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'July 7, 2021',
    publishedTime: '2021-07-07T13:30:04.609Z',
    dateModified: 'July 8, 2021', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 17,
    // MUST BE INCREMENTED !!!!!
    pageTitle:
      'Eliminating Redundancy with NestJS CLI Plugins. Introducing Comments Introspection',
    titleEnd: ' ',
    htmlTitleDisplay:
      'Eliminating Redundancy with <span class="trilon-pink">NestJS CLI Plugins</span>. Comments Introspection',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle:
      'Eliminating Redundancy with NestJS CLI Plugins. Introducing Comments Introspection',
    description:
      'Learn more about the NestJS CLI Plugins and how to use them to drastically eliminate redundancy, speed up the development, and improve overall application maintainability.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/eliminating-redundancy-with-nestjs-cli-plugins`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'eliminating-redundancy-with-nestjs-cli-plugins.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.JavaScript],
    date: 'October 5, 2020',
    publishedTime: '2020-10-05T12:58:21.505Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 16,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Introducing CLI Resource Generators: NestJS CRUD in 1 minute',
    titleEnd: ' ',
    htmlTitleDisplay:
      'Introducing CLI Resource Generators: <span class="trilon-pink">NestJS CRUD</span> in 1 minute 🚀',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Introducing CLI Resource Generators: NestJS CRUD in 1 minute',
    description:
      'Learn about the new NestJS CLI Resource Generators and how to use them to greatly speed up the development process.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/introducing-cli-generators-crud-api-in-1-minute`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName:
      'introducing-nestjs-cli-resource-generators-nestjs-crud-in-1-minute.png',
    tags: [TAGS.NestJS, TAGS.NodeJS],
    date: 'September 22, 2020',
    publishedTime: '2020-09-22T14:58:21.505Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 4,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 15,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Dealing with Late Subscribers in RxJS',
    htmlTitleDisplay:
      'Dealing with <span class="trilon-pink">Late Subscribers</span> in RxJS',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Dealing with Late Subscribers in RxJS',
    description:
      'Learn several ways to handle incoming Rx values that arrive before a Subscription has happened.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/dealing-with-late-subscribers-in-rxjs`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'the-late-subscriber-problem-in-rxjs.png',
    tags: [TAGS.RxJS, TAGS.ReactiveProgramming, TAGS.Angular], // we can use these later
    date: 'July 21, 2020',
    publishedTime: '2020-07-21T14:58:21.505Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 9,
    author: AUTHORS.MICHAEL_HLADKY,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 14,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Announcing the Official NestJS Course',
    htmlTitleDisplay:
      'Announcing the Official <span class="trilon-pink">NestJS Course</span>',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Announcing the Official NestJS Course',
    description:
      'Announcing the Official NestJS Course - Learn all the fundamentals of NestJS in this hands-on 5+ hour course from the NestJS creator and core team members themselves!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/announcing-the-official-nestjs-course`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'announcing-official-nestjs-course.png',
    tags: [TAGS.NestJS, TAGS.NodeJS, TAGS.Courses], // we can use these later
    date: 'June 29, 2020',
    publishedTime: '2020-06-29T10:36:27.710Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 13,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Introducing Mapped Types for NestJS',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Introducing Mapped Types for NestJS',
    description:
      'Learn about the new NestJS Mapped Types and how to use them to drastically reduce the amount of boilerplate code required.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/introducing-mapped-types-for-nestjs`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'introducing-mapped-types-for-nestjs.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'April 10, 2020',
    publishedTime: '2020-04-10T12:36:27.710Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 4,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 12,
    // MUST BE INCREMENTED !!!!!
    pageTitle: 'Announcing NestJS 7: What’s New',
    titleEnd: '?',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Announcing NestJS 7: What’s New? 🍷',
    description:
      'Today I am excited to announce the official release of Nest 7: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/announcing-nestjs-7-whats-new`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    imageName: 'announcing-nestjs-7--whats-new.png',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'March 13, 2020',
    publishedTime: '2020-03-13T13:30:04.609Z',
    dateModified: '', // [Not being used currently] Written version ie: 'March 13, 2020'
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 11,
    // ^ MUST BE INCREMENTED !!!!!
    pageTitle: `Angular Universal v9: What's New?`,
    htmlTitleDisplay: `<span class="trilon-pink">Angular Universal</span> v9: What's New`,
    titleEnd: '?',
    subTitle: `Angular Universal: What's New?`,
    description: `Learn about the new & improved Angular Universal schematics & builders, bringing you incredible developer experience and automatic static prerendering & server-side rendering for your Angular apps!`,
    link: '/blog/angular-universal-v9-whats-new',
    imageName: 'angular-universal-v9-whats-new.png',
    tags: [TAGS.Angular, TAGS.AngularUniversal, TAGS.SSR, TAGS.NodeJS],
    date: 'January 5, 2020',
    publishedTime: '2020-01-05T17:26:01.005Z',
    // If you update an article:
    // copy(new Date().toISOString()) and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    dateModified: 'February 21, 2020', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    readLength: 8,
    author: AUTHORS.MARK_PIESZAK,
  },
  {
    // MUST BE INCREMENTED !!!!!
    id: 10,
    // ^ MUST BE INCREMENTED !!!!!
    pageTitle: 'What’s new in NestJS Swagger 4?',
    htmlTitleDisplay:
      'What’s new in <span class="trilon-pink">NestJS</span> Swagger 4',
    titleEnd: '?',
    subTitle: 'What’s new in NestJS Swagger 4?',
    description:
      'Learn what’s new in NestJS Swagger 4 and how you can setup a newly released AST-based plugin in a few minutes!',
    link: '/blog/nestjs-swagger-4-whats-new',
    imageName: 'nestjs-swagger-4-whats-new.png',
    tags: [TAGS.NestJS, TAGS.NodeJS],
    date: 'December 3, 2019',
    publishedTime: '2019-12-03T14:04:10.479Z',
    // If you update an article:
    // copy(new Date().toISOString()) and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    dateModified: '', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    id: 9,
    pageTitle: 'Using NestJS Microservices with Laravel',
    htmlTitleDisplay:
      'Using NestJS Microservices with <span class="trilon-pink">Laravel</span>',
    subTitle: 'Communicating between NestJS and Laravel',
    description:
      'Learn how to communicate between NestJS and Laravel by setting up a Redis server and listening to request/responses with Laravel from a NestJS microservice.',
    link: '/blog/using-nestjs-microservices-with-laravel',
    imageName: 'using-nestjs-microservices-with-laravel.png',
    tags: [TAGS.NestJS, TAGS.Microservices, TAGS.Laravel, TAGS.Redis],
    date: 'November 19, 2019',
    publishedTime: '2019-11-19T09:30:58.493Z',
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    dateModified: '', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    readLength: 7,
    author: AUTHORS.GUESTS.BRIAN_JOHNSON,
  },
  {
    id: 8,
    pageTitle: 'Announcing NestJS Monorepos and new CLI commands',
    htmlTitleDisplay:
      'Announcing NestJS <span class="trilon-pink">Monorepos</span> and new CLI commands',
    subTitle: 'NestJS Monorepos and new CLI commands',
    description:
      'Learn how to manage multiple NestJS projects in a single monorepo using the NEW NestJS CLI features, and other great new tools!',
    link: '/blog/announcing-nestjs-monorepos-and-new-commands',
    imageName: 'announcing-nestjs-monorepos-and-new-cli-commands.png',
    tags: [TAGS.NestJS, TAGS.NodeJS],
    date: 'October 1, 2019',
    publishedTime: '2019-10-01T11:50:46.785Z',
    // If you update an article:
    // new Date().toISOString() and place here
    modifiedTime: '2022-09-06T18:26:24.611Z',
    dateModified: 'October 2, 2019', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    readLength: 4,
    author: AUTHORS.KAMIL_MYSLIWIEC,
  },
  {
    id: 7,
    pageTitle: 'Introducing NoSQL Azure Table Storage for NestJS',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'NoSQL Azure Table Storage and NestJS',
    description:
      'Learn how to use NoSQL Azure Table Storage inside your NestJS serverless application in just a few minutes!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/nestjs-nosql-azure-table-storage`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'nestjs-nosql-azure-table-storage.png',

    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.NestJS, TAGS.Azure, TAGS.Serverless], // we can use these later
    date: 'September 17, 2019',
    publishedTime: '2019-09-17T15:00:08.926Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: '', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 7,
    author: AUTHORS.GUESTS.WASSIM_CHEGHAM,
  },
  {
    id: 6,
    pageTitle: 'Deploy NestJS Serverless Apps to Azure Functions',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Deploy NestJS Serverless Apps to Azure Functions',
    description:
      'Learn about the new NestJS Schematics and how to setup & deploy NestJS Serverless apps to Azure Functions in a few minutes!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/deploy-nestjs-azure-functions`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'deploy-serverless-nestjs-azure-functions.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    // blogListingImage: 'https://miro.medium.com/fit/c/1400/420/0*dTSSrOZnuJzcrvxE',

    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.NestJS, TAGS.Azure, TAGS.Deployments], // we can use these later
    date: 'August 15, 2019',
    publishedTime: '2019-08-15T15:49:58.739Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: '', // put normal String version if modifiedTime has changed (ie: 'September 12, 2019' etc)
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 5,
    author: AUTHORS.KAMIL_MYSLIWIEC,
    collaborator: AUTHORS.MARK_PIESZAK,
  },
  {
    id: 5,
    pageTitle: 'How to Delete ALL node_modules folders on your machine',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'How to Delete ALL node_modules folders on your machine',
    description:
      'Delete all node_modules on your machine recursively while leaving projects intact. Learn how to free up space on your Mac/PC in 1-line of code!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/how-to-delete-all-nodemodules-recursively`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'how-to-delete-all-nodemodules-recursively.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    // blogListingImage: 'https://miro.medium.com/fit/c/1400/420/0*dTSSrOZnuJzcrvxE',

    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.NPM, TAGS.Productivity], // we can use these later
    date: 'August 7, 2019',
    publishedTime: '2019-08-07T18:02:08.720Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: 'February 10, 2020',
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 2,
    author: AUTHORS.MARK_PIESZAK,
  },

  {
    id: 4,
    pageTitle: 'Deploying NestJS Apps to Zeit Now',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Deploying NestJS Apps to Zeit Now',
    description:
      'Learn how to deploy NestJS apps to the Cloud with Zeit Now in 2 minutes!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/deploying-nestjs-to-zeit-now`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    imageName: 'deploy-nestjs-apps-to-zeit-now.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    // blogListingImage: 'https://miro.medium.com/fit/c/1400/420/0*dTSSrOZnuJzcrvxE',

    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.NestJS, TAGS.Zeit, TAGS.Now, TAGS.Deployments], // we can use these later
    date: 'July 17, 2019',
    publishedTime: '2019-07-17T16:17:25.078Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: 'September 5, 2019',
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 4,
    author: AUTHORS.MARK_PIESZAK,
  },
  {
    id: 3,
    pageTitle: 'Announcing NestJS 6: What’s New',
    titleEnd: '?',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Announcing NestJS 6: What’s New? 🍷',
    description:
      'Today I am thrilled to announce the official release of Nest 6: A progressive Node.js framework for building efficient and enterprise-grade, server-side applications.',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/announcing-nestjs-6-whats-new`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'something.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    blogListingImage:
      'https://miro.medium.com/fit/c/1400/420/0*dTSSrOZnuJzcrvxE',
    tags: [TAGS.NestJS, TAGS.NodeJS], // we can use these later
    date: 'March 18, 2019',
    publishedTime: '2019-03-18T12:30:34.918Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: 'July 13, 2019',
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 6,
    author: AUTHORS.KAMIL_MYSLIWIEC,
    // [OPTIONAL] ** (If original post was from somewhere else) **
    canonicalUrl:
      'https://medium.com/@kammysliwiec/announcing-nestjs-6-whats-new-38959d94221f',
  },
  {
    id: 2,
    pageTitle: 'Debugging Angular CLI Apps inside VSCode with Browser Preview',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Debugging Angular CLI Apps inside VSCode with Browser Preview',
    description:
      'Debug Angular-CLI Applications all inside of the VSCode IDE with this new extension!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/debugging-angular-cli-inside-vscode-with-browser-preview`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'testblog.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    blogListingImage:
      'https://miro.medium.com/max/4648/1*QGT8pfEQp7IMVQXNC5Tl2w.png',
    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.VSCode, TAGS.AngularCLI, TAGS.Debugging], // we can use these later
    date: 'January 25, 2019',
    publishedTime: '2019-01-25T20:30:34.918Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: 'July 13, 2019',
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 4,
    author: AUTHORS.MARK_PIESZAK,
    // [OPTIONAL] ** (If original post was from somewhere else) **
    canonicalUrl:
      'https://medium.com/@MarkPieszak/debugging-angular-cli-inside-vscode-with-browser-preview-8dcc4b18ed64',
  },
  {
    id: 1,
    pageTitle: 'Angular Universal Server-Side Rendering Deep-Dive',
    // used for Facebook "check out this Angular Universal Deep-Dive Tutorial"
    subTitle: 'Angular Universal Deep-Dive Tutorial',
    description:
      'Learn about Angular Universal, and how you can get SEO, social-media previews, and faster First Paints with your Angular application today!',
    // This is literally the name of your blog/{file-name-is-your-url}.md
    link: `/blog/angular-universal-server-side-rendering-deep-dive`,
    // Social media preview image (USE PSD TEMPLATE) - place in /static/social-cards
    // imageName: 'testblog.png',

    // Image to show on Blog Listing page (eventually we will make this the imageName)
    // (once the template is finished and we similar-looking Cards)
    blogListingImage:
      'https://miro.medium.com/max/4800/1*ZcjP3Pk28Ois-F-21NfIAg.png',
    // FIRST TAG is the most important it's your `<article:section>`, AND facebook #hashtag (they only allow one)
    // All of the tags become:
    //   `<article:tags>` meta, listed on the page, and listed in a Retweet
    tags: [TAGS.Angular, TAGS.AngularUniversal, TAGS.SSR, TAGS.SEO], // we can use these later
    date: 'September 9, 2018',
    publishedTime: '2018-09-06T17:41:20.074Z',
    // If you update an article:
    // new Date().toISOString() and place here
    dateModified: 'July 2, 2019',
    modifiedTime: '2022-09-06T18:26:24.611Z',
    readLength: 8,
    author: AUTHORS.MARK_PIESZAK,
    // [OPTIONAL] ** (If original post was from somewhere else) **
    canonicalUrl:
      'https://medium.com/@MarkPieszak/angular-universal-server-side-rendering-deep-dive-dc442a6be7b7',
  },
];

export const useMainStore = defineStore('main', {
  state: () => ({
    blogPosts,
    currentTalk: 0,
    conferenceTalks: CONFERENCE_TALKS.filter((talk) => talk.homepage !== false),
    teamMembers: TEAM.map((member) => {
      member.videos = CONFERENCE_TALKS.filter(
        (talk) => talk.name === member.name
      );
      member.recentArticles = blogPosts
        .filter((article) => article.author.name === member.name)
        .slice(0, 3);
      return member;
    }),

    openJobs: JOBS,

    currentTestimonial: 0,
    testimonials: [
      {
        id: 0,
        author: 'Asim Hussain',
        avatar: '/assets/testimonials/asim-hussain.png',
        jobPosition: 'EMEA Lead Regional Cloud Advocacy @ Microsoft',
        text: `We've been working closely with the NestJS & <strong>Trilon</strong> team to add DB support with Azure Table Storage, file upload support with Azure Blob Storage and most excitingly support for deploying a NestJS app to Azure Serverless Functions; it's the perfect companion for building server-sides on Microsoft Azure.`,
      },
      {
        id: 1,
        author: 'Natalie Mazza',
        avatar: '/assets/testimonials/natalie-mazza.jpeg',
        jobPosition: 'Partner, Vice President of Product @ OTG Management',
        text: `At the onset of a new project, we were looking for experts to help us understand a new tech stack (with NestJS) and implement it quickly and seamlessly. We sought out <strong>Trilon</strong> as the experts in the space and were thrilled we did so. They were able to quickly get our team up to speed on best practices and made themselves readily available for questions at all times. It was a joy to work with the Trilon team!`,
      },
      {
        id: 2,
        author: 'Josef Schiestl',
        avatar: '/assets/testimonials/placeholder.png',
        jobPosition: 'Global Mobile Solutions @ Munich Re',
        text: `When developing modern software solutions for mobile and web, it is crucial to put them on top of loosely coupled, testable and easy maintainable backend systems. To achieve this goal we use since recently the <strong>NestJS</strong> framework that provides us with an out of the box backend application architecture. Inspired by Angular and written in TypeScript, NestJS fits ideally in our development tool stack. Modularity, dependency injection and custom providers are only some of many useful features we benefit from when using NestJS. In close collaboration with <strong>Trilon.io</strong>, the consulting company behind NestJS, the framework allowed us to achieve our goals in fast and professional manner.`,
      },
      {
        id: 3,
        author: 'Ben Postlethwaite',
        avatar: '/assets/testimonials/ben-postlethwaite.jpeg',
        jobPosition: 'VP Engineering @ Plotly',
        text: `Plotly chose NestJS as one of the technologies for a major new product initiative. In fact, the move to Node for some of our backend services was a new shift for the engineering team. Trilon was invaluable training the team, establishing best practices early and helping navigate the many dependencies we assembled to deliver a quality build and product. Thank you Mark and Kamil and the Trilon team for being there every step of the way!`,
      },
      {
        id: 4,
        author: 'Keith Dutton',
        avatar: '/assets/testimonials/keith-dutton.png',
        jobPosition: 'Engineering Director @ WELL Health',
        text: `We chose NestJS as the target platform for our monolith to microservice refactor.  It is all we had hoped it would be.  We engaged Trilon from the start because we wanted to make sure that we were doing it right.  They were invaluable in helping us set our initial app structure, dependencies, standards...and reviewing code, and answering many questions along the way.  Not to mention writing inspiring code that our engineers learned from.  And it certainly helped in recruiting to mention that we had Trilon on retainer. Thanks Mark and Kamil for helping us ramp on your superb framework.`,
      },
      {
        id: 5,
        author: 'Zackary Chapple',
        avatar: '/assets/testimonials/zackary-chapple.jpg',
        jobPosition: 'Principal Architect @ CareerBuilder',
        text: `<strong>Trilon</strong> has helped level-up our teams skills and enterprise best-practices from front-end to back-end development and surrounding ecosystems. Their expertise helped us not only finish products on-time, but helped us create sustainable and reliable software.`,
      },
    ],
  }),
  actions: {
    changeTalk(index) {
      this.currentTalk = index;
    },
    changeTestimonial(index) {
      this.currentTestimonial = index;
    },
  },
});
