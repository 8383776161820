<template>
  <div class="blog-layout">
    <section class="intro intro--no-waves">
      <div class="container">
        <Navigation :currentTab="'blog'" />

        <div v-if="blogData.author" class="container-blog container text-left">
          <!-- <h6 class="text-uppercase"><a  fhref="/blog"><< Back to All Articles</a></h6> -->

          <!-- Show title dynamically here -->
          <h1 class="trilon-title">
            <span
              v-if="blogData.htmlTitleDisplay"
              v-html="blogData.htmlTitleDisplay"
            ></span>
            <span v-if="!blogData.htmlTitleDisplay">{{ blogData.pageTitle }}</span>
            <span v-if="!blogData.titleEnd" class="trilon-pink">.</span>
            <span v-if="blogData.titleEnd" class="trilon-pink">{{
              blogData.titleEnd
            }}</span>
          </h1>

          <!-- [Potentially for later] - Show tags? -->
          <!-- <div class="tag-wrapper">
            <div v-for="tag in blogData.tags" :key="tag" class="blog-tag">{{ tag }}</div>
          </div>-->

          <!-- <h2 v-if="blogData.subTitle" class="trilon-slogan">{{ blogData.subTitle }}
          </h2>-->
          <!--
          <h3 v-if="blogData.author" class="mt-5">
            By <strong>{{ blogData.author }}</strong>
            <span v-if="blogData.date"> on {{ blogData.date }}</span>
          </h3>-->
        </div>
      </div>
    </section>

    <div v-if="!blogData.author" class="container-blog container text-left">
      <h1 class="trilon-title">
        {{ blogData.pageTitle }}
        <span class="trilon-pink">.</span>
      </h1>
    </div>

    <div class="intro-waves"></div>
    <!-- <section class="container wave-wrapper-upper-only blog-post-wrapper"> -->
    <section class="wave-wrapper">
      <div
        v-if="blogData.author"
        class="container container-author container-blog text-left"
      >
        <div @click="scrollTo('#about-author')" id="author-row" class="row">
          <img
            :src="blogData.author.photo"
            :alt="blogData.author.name + ' | Trilon Consulting'"
            class="blog-photo rounded-circle"
          />
          <h6 class="pt-2 pl-3 pr-4">
            <strong>{{ blogData.author.name }}</strong>
            <br />

            <span v-if="blogData.date" class="post-date">
              <!-- computer properties don't show up in SSR version??? -->
              <!-- on {{ displayPublished }} -->
              on {{ blogData.date }}
              <span v-if="blogData.readLength">, {{ blogData.readLength }} min read</span>
            </span>
          </h6>
          <!-- collaborator section -->
          <div v-if="blogData.collaborator" class="collaborator-wrapper pl-3">
            <div class="row mt-2">
              <img
                :src="blogData.collaborator.photo"
                :alt="blogData.collaborator.name + ' | Trilon Consulting'"
                class="collaborator-photo blog-photo rounded-circle ml-3"
              />
              <h6 class="pt-1 pl-3">
                <strong>{{ blogData.collaborator.name }}</strong>
                <br />
                <em style="font-size: 0.75rem">Collaborator</em>
                <br />
              </h6>
              <br class="clearfix" />
            </div>
          </div>

          <!-- end collaborator -->
        </div>

        <div class="author-top-socials">
          <a
            v-if="blogData.author.twitter"
            :href="'https://twitter.com/' + blogData.author.twitter"
            rel="noopener"
            target="_blank"
            class="social-link"
          >
            <svg
              aria-hidden="true"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#fff"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </a>

          <a
            v-if="blogData.author.github"
            :href="'https://github.com/' + blogData.author.github"
            rel="noopener"
            target="_blank"
            class="social-link"
          >
            <svg
              aria-hidden="true"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 496 512"
              data-fa-i2svg
            >
              <path
                fill="#fff"
                d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
              />
            </svg>
          </a>

          <a
            v-if="blogData.author.linkedin"
            :href="'https://www.linkedin.com/in/' + blogData.author.linkedin"
            rel="noopener"
            target="_blank"
            class="social-link"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin"
              class="svg-inline--fa fa-linkedin fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fff"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
          </a>

          <a
            v-if="blogData.author.youtube"
            :href="'https://www.youtube.com/channel/' + blogData.author.youtube"
            rel="noopener"
            target="_blank"
            class="social-link"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="youtube-square"
              class="svg-inline--fa fa-youtube-square fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fff"
                d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
              />
            </svg>
          </a>
        </div>
      </div>

      <section
        class="blog-post-wrapper dark-bg"
        :class="blogData.author ? '' : 'blog-list-wrapper'"
      >
        <div class="sticky-social">
          <SocialLinks v-if="blogData.author" :data="blogData"></SocialLinks>
        </div>

        <div v-if="blogData.author" class="sticky-mobile-social">
          <SocialLinks
            :data="blogData"
            :mobile="true"
            :horizontal="true"
            :bottom-mobile-bar="true"
          ></SocialLinks>
        </div>

        <div class="container-blog container-blog-content container">
          <div class="blog-post dark-bg">
            <b-breadcrumb v-if="blogData.author" :items="breadcrumbs" />

            <div v-if="blogData.dateModified">
              <!-- <strong>Last updated: {{ displayModified }}</strong> -->
              <!-- <strong><em>Updated on {{ blogData.dateModified }}</em></strong> -->
              <!-- <br> -->
            </div>

            <!-- where Nuxt is going to render everything into -->
            <slot />
            <!-- where Nuxt is going to render everything into -->

            <div v-if="showAuthCourseSection">
              <hr />
              <h2>Want to learn more about NestJS Authentication?</h2>

              Learn how to create your own <em>hand-written</em> Authentication and
              Authorization with NestJS. Take full control over your Authn/Authz from
              access to refresh tokens, real-world JWTs, Google integration, 2FA (2-Factor
              Authentication), and so much more.<br /><br />

              <blockquote class="alternate">
                🚀 Official
                <a :href="'https://courses.nestjs.com/#authentication'"
                  >NestJS Authentication Course</a
                >
                is now in <strong>LIVE!</strong>
              </blockquote>

              <br />
              Level-up your NestJS and Node.js ecosystem skills in these incremental
              workshop-style courses, from the NestJS Creator himself, and help support
              the NestJS framework! 🐈<br />
              <!-- <blockquote class="">
                🚀 The <strong><a :href="'https://courses.nestjs.com/?trilon--blog-' + blogData.pageTitle">NestJS Fundamentals Course</a></strong> is now <strong>LIVE</strong> and 25% off for a limited time!
              </blockquote> -->
              <br />

              <strong
                ><span class="trilon-pink">📚</span> Check out the other Official NestJS
                Courses!</strong
              >

              <ul>
                <li>
                  <a
                    :href="
                      'https://courses.nestjs.com/?trilon--blog-' + blogData.pageTitle
                    "
                  >
                    <strong>NestJS Fundamentals Course</strong>
                  </a>
                  is 25% off for a limited time!
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#advanced-concepts'"
                    >NestJS Advanced Concepts Course</a
                  >
                  now <strong>LIVE!</strong>
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#advanced-bundle'"
                    >NestJS Advanced Bundle (Advanced Architecture and Advanced Concepts)</a
                  >
                  now <strong>22% OFF!</strong>
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#graphql-bundle'"
                    >NestJS GraphQL Courses</a
                  >
                  (code-first & schema-first approaches) are now
                  <strong>LIVE</strong>
                </li>
              </ul>
            </div>

            <div v-if="!showAuthCourseSection && !disableCourseSection">
              <hr />
              <h2>Learn NestJS - Official NestJS Courses 📚</h2>

              Level-up your NestJS and Node.js ecosystem skills in these incremental
              workshop-style courses, from the NestJS Creator himself, and help support
              the NestJS framework! 🐈<br /><br />

              <blockquote class="">
                🚀 The
                <strong
                  ><a
                    :href="
                      'https://courses.nestjs.com/?trilon--blog-' + blogData.pageTitle
                    "
                    >NestJS Fundamentals Course</a
                  ></strong
                >
                is now <strong>LIVE</strong> and 25% off for a limited time!
              </blockquote>
              <br />

              <strong
                ><span class="trilon-pink">🎉 <em>NEW</em></span> - NestJS Course
                Extensions now live!</strong
              >

              <ul>
                <li>
                  <a :href="'https://courses.nestjs.com/#advanced-concepts'"
                    >NestJS Advanced Concepts Course</a
                  >
                  now <strong>LIVE</strong>!
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#advanced-bundle'"
                    >NestJS Advanced Bundle (Advanced Architecture and Advanced Concepts)</a
                  >
                  now <strong>22% OFF!</strong>
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#microservices'"
                    >NestJS Microservices</a
                  >
                  now <strong>LIVE</strong>!
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#authentication'"
                    >NestJS Authentication / Authorization Course</a
                  >
                  now <strong>LIVE</strong>!
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#graphql-bundle'"
                    >NestJS GraphQL Course</a
                  >
                  (code-first & schema-first approaches) are now
                  <strong>LIVE</strong>!
                </li>
                <li>
                  <a :href="'https://courses.nestjs.com/#authentication'"
                    >NestJS Authentication / Authorization Course</a
                  >
                  now <strong>LIVE</strong>!
                </li>
              </ul>
            </div>

            <div v-if="blogData.tags" class="tag-wrapper pt-5">
              <div v-for="tag in blogData.tags" :key="tag" class="blog-tag">
                {{ "#" + tag }}
              </div>
            </div>

            <div id="bottom-social">
              <SocialLinks
                v-if="blogData.author"
                :data="blogData"
                :horizontal="true"
                :bottom-mobile-bar="false"
              />

              <!-- NEWSLETTER -->
              <div id="newsletter">
                <h2>📬 Trilon Newsletter</h2>

                <blockquote v-if="signedUp">
                  <strong>Welcome!</strong> You have signed up!
                </blockquote>
                <blockquote v-if="error" class="alternate">
                  <strong>Error:</strong> Something went wrong.
                </blockquote>
                <p v-if="!signedUp">
                  Stay up to date with all the latest Articles & News!
                </p>
                <form class="form-wrapper">
                  <div class="input-wrapper">
                    <input
                      v-model="email"
                      id="email"
                      name="email"
                      type="email"
                      class="form-control"
                      placeholder="Write your email..."
                      :disabled="isSubmitted"
                      required
                    />
                    <!-- pattern="[\s]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" -->
                    <!-- pattern="[^@\s]+@[^@\s]+" -->
                    <!-- pattern="^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$" -->
                  </div>

                  <div class="button-wrapper">
                    <button
                      :disabled="isSubmitted"
                      @click="newsletterSignup($event)"
                      type="submit"
                      class="newsletter-add d-block btn btn-primary"
                    >
                      Keep me updated
                      <span class="icon">
                        <i class="fas fa-paper-plane"></i>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="blog-post-wrapper dark-bg">
        <!-- [TODO] move to component -->
        <!-- hide if former -->
        <div
          id="about-author"
          v-if="blogData.author && !blogData.author.hideAuthorSection"
          class="author-footer-wrapper"
        >
          <div class="container container-blog">
            <div class="row">
              <!-- why isn't order-xs-2 working here?? -->
              <div
                class="author-photo-wrapper col-md-4 col-sm-12 order-1 order-md-1 order-sm-2 order-xs-2 text-center"
              >
                <img
                  :src="blogData.author.photo"
                  :alt="blogData.author.name + ' | Trilon Consulting'"
                  class="blog-photo-large rounded-circle"
                />
                <h3 class="pt-4 author-name-section">
                  <span class="color-primary author-name">{{
                    blogData.author.name
                  }}</span>
                  <span v-if="blogData.author.role">
                    <br />
                    {{ blogData.author.role }}
                  </span>
                </h3>

                <a
                  v-if="blogData.author.twitter"
                  :href="'https://twitter.com/' + blogData.author.twitter"
                  rel="noopener"
                  target="_blank"
                  class="social-link"
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#fff"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                </a>

                <a
                  v-if="blogData.author.github"
                  :href="'https://github.com/' + blogData.author.github"
                  rel="noopener"
                  target="_blank"
                  class="social-link"
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="#fff"
                      d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                    />
                  </svg>
                </a>

                <a
                  v-if="blogData.author.linkedin"
                  :href="'https://www.linkedin.com/in/' + blogData.author.linkedin"
                  rel="noopener"
                  target="_blank"
                  class="social-link"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin"
                    class="svg-inline--fa fa-linkedin fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#fff"
                      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                    />
                  </svg>
                </a>

                <a
                  v-if="blogData.author.youtube"
                  :href="'https://www.youtube.com/channel/' + blogData.author.youtube"
                  rel="noopener"
                  target="_blank"
                  class="social-link"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="youtube-square"
                    class="svg-inline--fa fa-youtube-square fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#fff"
                      d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
                    />
                  </svg>
                </a>
              </div>

              <div class="col-md-8 col-sm-12 order-md-2 order-sm-1 order-xs-1 mt-3 mb-5">
                <h2>
                  About the Author
                  <span v-if="blogData.collaborator">s</span>
                </h2>

                <span v-html="blogData.author.bio"></span>

                <br />
                <br />
                <br />

                <!-- collaborator section -->
                <div v-if="blogData.collaborator" class="mt-2 mb-4">
                  <div class="row mt-2">
                    <img
                      :src="blogData.collaborator.photo"
                      :alt="blogData.collaborator.name + ' | Trilon Consulting'"
                      class="collaborator-photo blog-photo rounded-circle ml-3"
                    />
                    <h6 class="pt-1 pl-3">
                      <em style="font-size: 0.75rem">Collaborator:</em>
                      <br />
                      <strong>{{ blogData.collaborator.name }}</strong>
                      <br />
                      <br />

                      <div class="author-top-socials">
                        <a
                          v-if="blogData.collaborator.twitter"
                          :href="'https://twitter.com/' + blogData.collaborator.twitter"
                          rel="noopener"
                          target="_blank"
                          class="social-link"
                        >
                          <svg
                            aria-hidden="true"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#fff"
                              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            />
                          </svg>
                        </a>

                        <a
                          v-if="blogData.collaborator.github"
                          :href="'https://github.com/' + blogData.collaborator.github"
                          rel="noopener"
                          target="_blank"
                          class="social-link"
                        >
                          <svg
                            aria-hidden="true"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 496 512"
                            data-fa-i2svg
                          >
                            <path
                              fill="#fff"
                              d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                            />
                          </svg>
                        </a>

                        <a
                          v-if="blogData.collaborator.linkedin"
                          :href="
                            'https://www.linkedin.com/in/' +
                            blogData.collaborator.linkedin
                          "
                          rel="noopener"
                          target="_blank"
                          class="social-link"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="linkedin"
                            class="svg-inline--fa fa-linkedin fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#fff"
                              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                            />
                          </svg>
                        </a>

                        <a
                          v-if="blogData.collaborator.youtube"
                          :href="
                            'https://www.youtube.com/channel/' +
                            blogData.collaborator.youtube
                          "
                          rel="noopener"
                          target="_blank"
                          class="social-link"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="youtube-square"
                            class="svg-inline--fa fa-youtube-square fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#fff"
                              d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
                            />
                          </svg>
                        </a>
                      </div>
                      <!-- <a
                  :href="'https://twitter.com/' + blogData.collaborator.twitter"
                  rel="noopener"
                  target="_blank"
                  class="social-link ml-0"
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#fff"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                </a>

                <a
                  :href="'https://github.com/' + blogData.collaborator.github"
                  rel="noopener"
                  target="_blank"
                  class="social-link"
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="#fff"
                      d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                    />
                  </svg>
                      </a>-->
                    </h6>
                    <br class="clearfix" />
                  </div>
                </div>
                <!-- end collaborator -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="dark-bg container more-blog-wrapper"
          v-if="otherBlogPosts && otherBlogPosts.length"
        >
          <div class="row">
            <!-- {{ otherBlogPosts | json }} -->
            <h2 class="mega-font heading col-12 mb-5">
              More from the Trilon Blog
              <span class="trilon-pink heading-dot">.</span>
            </h2>

            <div v-for="blog in otherBlogPosts" :key="blog.id" class="col-lg-4 col-md-12">
              <div v-if="blog.author" class="text-left blogpost-card">
                <!-- listing-author-wrapper -->
                <div class="row">
                  <img
                    :src="blog.author.photo"
                    :alt="blog.author.name + ' | Trilon Consulting'"
                    class="author-photo ml-3 rounded-circle"
                  />
                  <h6 class="pt-2 pl-3">
                    <strong>{{ blog.author.name }}</strong>
                    <br />
                    <span v-if="blog.date" class="post-date">
                      on {{ blog.date }}
                      <span v-if="blog.readLength"></span>
                    </span>
                  </h6>
                </div>

                <h4>
                  <a v-bind:href="blog.link">{{ blog.pageTitle }}</a>
                </h4>
                <!-- d-lg-none -->

                <p class="blog-description d-block">{{ blog.description }}</p>
                <a :href="blog.link" class="btn btn-secondary">
                  Read More
                  <arrow />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <div class="dark-bg">
      <WhatWeDo class="pb-5" :atTrilon="true" :blogPage="!!blogData.author"></WhatWeDo>
    </div>
    <ContactUs class="pt-5"></ContactUs>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const route = useRoute();
const { $store: store } = useNuxtApp();

function getPostByRoutePath(path) {
  const hash = path.indexOf("#");
  if (hash > -1) {
    path = path.slice(0, hash);
  }

  const blog = {
    ...store.blogPosts.find((blogPost) => blogPost.link === path),
  };
  if (!blog.pageTitle) {
    return {
      pageTitle: "Trilon.io Blog",
      subTitle: "Come check out the latest stories",
    };
  } else {
    return blog;
  }
}

const blogData = computed(() => getPostByRoutePath(route.fullPath));
const otherBlogPosts = computed(() => {
  const blogData = getPostByRoutePath(route.fullPath);
  const { link } = blogData;

  if (link) {
    const blog = store.blogPosts.filter((blog) => blog.link !== link);
    return blog.splice(0, 3);
  }
  return null;
});
const disableCourseSection = ref(blogData.disableCourseSection);
const showAuthCourseSection = ref(blogData.showAuthCourseSection);
const displayPublished = computed(() => {
  const blogData = getPostByRoutePath(route.fullPath);
  const localeDate = new Date(blogData.publishedTime).toLocaleString();

  return localeDate.substr(0, localeDate.indexOf(","));
});
const displayModified = computed(() => {
  const blogData = getPostByRoutePath(route.fullPath);
  const localeDate = new Date(blogData.modifiedTime).toLocaleString();

  return localeDate.substr(0, localeDate.indexOf(","));
});
const hasBlogBeenModified = computed(() => {
  const blogData = getPostByRoutePath(route.fullPath);
  const modified = blogData.modifiedTime
    ? blogData.modifiedTime !== blogData.publishedTime
    : false;

  return modified;
});
const breadcrumbs = computed(() => {
  const blogData = getPostByRoutePath(route.fullPath);
  const list = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Trilon Blog",
      href: "/blog",
    },
  ];

  if (!blogData.blogList) {
    list.push({
      text: blogData.pageTitle,
      href: blogData.link,
    });
  }

  return list;
});
onMounted(() => {
  if (typeof document !== "undefined") {
    // document.addEventListener('DOMContentLoaded', (ev) => {
    // ********
    // Need to make sure all EXTERNAL Links go to _blank
    // ********
    let links = document.links;

    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
      if (
        links[i].hostname != window.location.hostname &&
        !links[i].href.includes("mailto")
      ) {
        if (!links[i].hostname.includes("nestjs.com")) {
          links[i].rel =
            links[i].rel && links[i].rel.includes("noopener")
              ? links[i].rel
              : `${links[i].rel} noopener`;
        }
        links[i].target = "_blank";
      }
    }

    // ********
    // Pre-tag Fixes (wrappers & code copy functionality)
    const preElems = document.querySelectorAll("pre");
    preElems.forEach((pre, index) => {
      const wrapper = document.createElement("div");
      wrapper.className = "pre-wrapper";

      const copyCode = document.createElement("span");
      copyCode.className = "code-copy";
      copyCode.innerHTML = '<span class="fa fa-copy"></span>';
      copyCode.id = `code-${index}`;

      // Wrap pre tags
      pre.parentNode.insertBefore(wrapper, pre);
      wrapper.appendChild(pre);
      wrapper.appendChild(copyCode);
      wrapper.querySelector("code").classList.add(`code-${index}`, "language-");

      copyCode.addEventListener("click", (ev) => {
        const codeToCopy = document
          .querySelector(`.${copyCode.id}`)
          .innerText.replace(/\$\s/g, "")
          .replace(/\☁/g, "");

        if (navigator.clipboard) {
          navigator.clipboard.writeText(codeToCopy).then(
            () => {
              /* clipboard successfully set */
              const toast = document.querySelector(".toast-clipboard-copy");
              toast.innerHTML = "<strong>Copied to Clipboard!</strong>";
              toast.style.display = "block";

              setTimeout(() => {
                toast.style.display = "none";
              }, 1000);
            },
            () => {
              /* clipboard write failed */
            }
          );
        }
      });
    });

    // ********
    // Headline auto-links
    let headlines = document.querySelectorAll(
      ".blog-post section h1, .blog-post section h2, .blog-post section h3, .blog-post section h4"
    );

    headlines.forEach((headline) => {
      const linkDiv = document.createElement("span");
      headline.id = headline.innerText.replace(/[^\w.]+/g, "-");
      linkDiv.className = "headline-autolink fa fa-link";

      headline.append(linkDiv);
    });

    // ********
    // Headline auto-link scroll
    const hash = window.location.hash;
    // if (hash) {
    //   setTimeout(() => {
    //     this.$scrollTo(hash, 500, { offset: -25 });
    //   }, 500);
    // }

    const copiedToClipElem = document.createElement("div");
    copiedToClipElem.className = "toast-clipboard-copy";
    copiedToClipElem.innerHTML = "<strong>URL Copied to Clipboard!</strong>";

    document.body.append(copiedToClipElem);

    // Headline click event
    headlines.forEach((headline) => {
      headline.addEventListener("click", (ev) => {
        const target = ev.target;
        let id = target.id;

        if (target.tagName === "SPAN") {
          id = target.parentNode ? target.parentNode.id : "";
        }

        if (window.history && window.history.pushState) {
          const url = window.location.origin + window.location.pathname;
          window.history.replaceState(null, null, url + `#${id}`);
        } else {
          window.location.hash = id;
        }

        if (navigator.clipboard) {
          navigator.clipboard.writeText(window.location).then(
            () => {
              /* clipboard successfully set */
              const toast = document.querySelector(".toast-clipboard-copy");
              toast.innerHTML = "<strong>URL Copied to Clipboard!</strong>";
              toast.style.display = "block";

              setTimeout(() => {
                toast.style.display = "none";
              }, 1000);
            },
            () => {
              /* clipboard write failed */
            }
          );
        }
      });
    });
  }
});
const structuredData = computed(() => {
  let structuredData;
  const blogData = getPostByRoutePath(route.fullPath);

  if (blogData && blogData.author) {
    structuredData = {
      "@context": "http://schema.org/",
      "@type": "NewsArticle",
      headline: blogData.pageTitle || "Trilon Blog",
      name: blogData.pageTitle || "Trilon Blog",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://trilon.io${blogData.link}`,
      },
      datePublished: blogData.publishedTime,
      datePublished: blogData.publishedTime,
      dateModified: blogData.modifiedTime || blogData.publishedTime,
      description: blogData.description,
      keywords: blogData.tags,
      image: {
        "@type": "ImageObject",
        height: "1200",
        width: "630",
        url: blogData.imageName
          ? `https://trilon.io/social-cards/${blogData.imageName}`
          : "https://trilon.io/meta/og-image.png",
      },
      author: blogData.author ? blogData.author.name : "Trilon",
      publisher: {
        "@type": "Organization",
        logo: {
          "@type": "ImageObject",
          url: "https://trilon.io/trilon-logo-clear.png",
        },
        name: "Trilon",
      },
    };

    // Blog Listing
  } else if (blogData) {
    structuredData = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": "https://trilon.io/#organization",
          name: "Trilon",
          url: "https://trilon.io/",
          sameAs: [],
          logo: {
            "@type": "ImageObject",
            "@id": "https://trilon.io/#logo",
            url: "https://trilon.io/trilon-logo-clear.png",
            caption: "Trilon",
          },
          image: {
            "@id": "https://trilon.io/#logo",
          },
        },
        {
          "@type": "WebSite",
          "@id": "https://trilon.io/#website",
          url: "https://trilon.io/",
          name: "Trilon",
          publisher: {
            "@id": "https://trilon.io/#organization",
          },
          potentialAction: {
            "@type": "SearchAction",
            target: "https://trilon.io/?search={search_term_string}",
            "query-input": "required name=search_term_string",
          },
        },
        {
          "@type": "WebPage",
          "@id": "https://trilon.io/blog#webpage",
          url: "https://trilon.io/blog",
          inLanguage: "en-US",
          name: "Blog - Trilon - Expert blogposts on fullstack development",
          isPartOf: {
            "@id": "https://trilon.io/#website",
          },
          description:
            "Guides, Articles and Tutorials and expert knowledge on Angular, NestJS, TypeScript and everything fullstack development - from Trilon",
        },
      ],
    };
  }
  return structuredData;
});
const email = ref("");
const submitting = ref(false);
const isSubmitted = ref(false);
const signedUp = ref(false);
const error = ref(false);

async function newsletterSignup(e) {
  e.preventDefault();
  const isValid = document.getElementById("email").validity
    ? document.getElementById("email").validity.valid
    : false;

  const emailValue = email.value;
  if (isValid || (emailValue.length > 6 && emailValue.includes("@") && emailValue.includes("."))) {
    isSubmitted.value = true;
    const url =
      "https://z93f42xq2l.execute-api.us-east-2.amazonaws.com/Stage/newsletter?token=df88f43ae9e52afdf1c8b5d89791547f";

    try {
      error.value = false;
      isSubmitted.value = true;

      const fetchRes = await $fetch(url, {
        method: 'POST',
        body: {
          email: emailValue,
        },
      });
      const res = await fetchRes.json();
      if (res === "") {
        signedUp.value = true;
      }
    } catch (ex) {
      submitting.value = false;
      error.value = true;
      console.error(ex);
      isSubmitted.value = false;
    }
  } else {
    console.log("Email not valid or too short");
    return;
  }
}
function scrollTo() {
  // TODO: find scrollTo Replacement
}
const title = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  return `${post.pageTitle} - Trilon Consulting`;
});
const author = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  return post.author ? `${blogData.author} - Trilon` : "";
});
const description = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  return post.description ?? "Trilon.io Blog";
});
const tags = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  const stringifiedTags = post.tags ? post.tags.join(", ") + ", " : "";
  return `${stringifiedTags}nestjs consulting, trilon`;
});
const url = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  return post.canonicalUrl ?? post.link
    ? `https://trilon.io${post.link}`
    : "https://trilon.io/blog";
});
const socialCardImage = computed(() => {
  const post = getPostByRoutePath(route.fullPath);
  return post.imageName
    ? `https://trilon.io/social-cards/${post.imageName}`
    : "https://trilon.io/meta/og-image.png";
});

useHead({
  title,
  link: [
    ...(blogData.canonicalUrl
      ? // Canonical (If EXTERNAL canonical (repost from some OTHER place))
        [
          {
            vmid: "rel:canonical",
            vmid: "description",
            rel: "canonical",
            href: url,
          },
        ]
      : // self referencing canonical otherwise
        [
          {
            vmid: "rel:canonical",
            rel: "canonical",
            href: url,
          },
        ]),
  ],
  author,
  meta: [
    // hid is used as unique identifier. Do not use `vmid` for it as it will not work

    ...(author ? [{ vmid: "author", name: "author", content: author }] : []),

    {
      vmid: "keywords",
      property: "keywords",
      content: tags,
    },

    // FB Open Graph
    {
      vmid: "og:url",
      property: "og:url",
      content: url,
    },
    { vmid: "og:locale", property: "og:locale", content: `en_US` },
    { vmid: "og:type", property: "og:type", content: `article` },
    {
      vmid: "og:site_name",
      property: "og:site_name",
      content: `Trilon`,
    },

    ...(blogData.tags
      ? [
          ...blogData.tags.map((tag) => ({
            vmid: `article:tag:${tag}`,
            property: "article:tag",
            content: tag,
          })),
          {
            vmid: "article:section",
            property: "article:section",
            content: blogData.tags[0],
          },
        ]
      : []),

    // Title
    { vmid: "og:title", property: "og:title", content: title },
    {
      vmid: "twitter:title",
      property: "twitter:title",
      content: title,
    },
    {
      vmid: "twitter:image:alt",
      property: "twitter:image:alt",
      content: title,
    },

    // Description
    { vmid: "description", name: "description", content: description },
    {
      vmid: "og:description",
      property: "og:description",
      content: description,
    },
    {
      vmid: "twitter:description",
      property: "twitter:description",
      content: description,
    },

    // Time Publish & Updated meta
    ...(blogData.publishedTime && blogData.modifiedTime
      ? [
          {
            vmid: "article:published_time",
            property: "article:published_time",
            content: blogData.publishedTime,
          },
          {
            vmid: "published_date",
            property: "published_date",
            content: blogData.publishedTime,
          },
          {
            vmid: "article:modified_time",
            property: "article:modified_time",
            content: blogData.modifiedTime || blogData.publishedTime,
          },
          {
            vmid: "og:updated_time",
            property: "og:updated_time",
            content: blogData.modifiedTime || blogData.publishedTime,
          },
        ]
      : []),

    // Social Media Preview Images
    {
      vmid: "og:image",
      property: "og:image",
      content: socialCardImage,
    },
    {
      vmid: "og:image:secure_url",
      property: "og:image:secure_url",
      content: socialCardImage,
    },
    {
      vmid: "twitter:image",
      property: "twitter:image",
      content: socialCardImage,
    },
  ],
  __dangerouslyDisableSanitizers: ["script"],
  script: [
    {
      innerHTML: JSON.stringify(structuredData.value),
      type: "application/ld+json",
    },
  ],
});
</script>

<style lang="scss">
.tag-wrapper {
  margin-top: 1rem;
}
.blog-tag {
  border-radius: 3px;
  font-size: 0.75rem;
  float: left;
  padding: 0.25rem 1rem;
  background: #171835;
  margin: 0 1rem 1rem;
}

.more-blog-wrapper {
  padding: 8rem 15px 10rem;
}

#newsletter {
  background: hsl(259, 70%, 10%);
  border-radius: 10px;
  padding: 3rem;
  margin: 5rem 0 0;

  h2 {
    margin: 0;
    padding: 0 0 2rem;
  }

  .button-wrapper {
    margin: 2rem 0 0 0;
  }

  blockquote {
    font-size: 16px;
  }
}

.blogpost-card {
  background: #0f0523;
  border-radius: 10px;
  // border: 1px #573e8c solid;
  border-top: 10px #2a2256 solid;
  /* background-color: white; */
  padding: 2rem;
  margin: 1rem 2rem;

  .blog-description {
    margin: 2rem 0;
    color: #fff;
    line-height: 1.75rem;
  }

  &:hover {
    background: #140e33;
    border-color: #453b79;
  }

  h4 {
    min-height: 180px;
    line-height: 2rem;
    padding-bottom: 0;

    @media (max-width: 991px) {
      min-height: auto;
      padding: 2rem 0 0;
    }
  }

  // Medium size
  @media (min-width: 991px) {
    position: relative;
    min-height: 660px;

    // display: flex;
    // flex-direction: column;
    // align-content: space-between;
    // flex-flow: wrap;

    .blog-description {
    }

    h4 {
      min-height: 135px;
    }

    a.btn {
      position: absolute;
      bottom: 2rem;
    }
  }

  // Large size
  @media (min-width: 1200px) {
    min-height: 600px;
  }

  @media (max-width: 1200px) {
    margin: 1rem 0;
  }
  @media (max-width: 991px) {
    margin: 1rem 2rem;
  }
  @media (max-width: 480px) {
    margin: 2rem 0.5rem;
    padding: 2rem 1rem;
  }
}

@media (max-width: 520px) {
  #author-row {
    margin-top: -1rem;
  }

  .collaborator-wrapper {
    padding-top: 0.5rem;
  }
}
</style>
