const MARK_PIESZAK = {
  name: 'Mark Pieszak',
  photo: `/assets/photos/mark-small.png`,
  role: 'Trilon Co-Founder',
  bio: `
    Mark Pieszak is the Co-Founder of <strong><a href="/">Trilon</a></strong>, and member of the
    NestJS Core Team & Angular Universal team. <br><br>With a passion for all things software
    development and open source - he has contributed to some of the largest open-source projects in ASP.NET,
    Angular, NestJS and many others.
    `,
  twitter: 'MarkPieszak',
  github: 'MarkPieszak',
  linkedin: 'mark-pieszak',
};

const KAMIL_MYSLIWIEC = {
  name: 'Kamil Mysliwiec',
  photo: `/assets/photos/kamil-small.png`,
  role: 'Trilon Co-Founder',
  bio: `
  Kamil Mysliwiec is the Co-Founder of <strong><a href="/">Trilon</a></strong>,
  Creator of <a href="https://nestjs.com">NestJS</a>, Google Developer Expert, and Cat-lover. 🐈<br><br>
  An enthusiastic software engineer who loves challenges, cutting-edge technologies, and programming it all. Open source contributor, and a passionate developer on a mission to change the Node.js world.
    `,
  twitter: 'kammysliwiec',
  github: 'kamilmysliwiec',
  linkedin: 'kamil-mysliwiec-992bbb105',
};

const MICHAEL_HLADKY = {
  name: 'Michael Hladky',
  photo: `/assets/photos-v2/michael.png`,
  role: 'Lead Instructor',
  bio: `
  Michael Hladky is a Lead Instructor at <strong><a href="/">Trilon</a></strong><br><br>

  Michael is an Rx Fanatic, Google Developer Expert (GDE), Microsoft MVP, Speaker, Dev, and Educator.
  He is a full-stack developer located in Vienna, Austria.  As co-founder of the Angular-Austria association, organizer of Angular-Vienna and NestJS-Vienna Meetup he grows the Austrian community. He is a developer, trainer and consultant with the focus on Angular as well as ionic and surrounding technologies, and gives workshops on Angular, RxJs and Typescript.
  `,
  twitter: 'michael_hladky',
  github: 'BioPhoton',
};

const DANIEL_DE_LUCCA = {
  name: 'Daniel De Lucca',
  photo: '/assets/photos-v2/daniel.png',
  role: 'Software Architect',
  bio: `Daniel De Lucca is a Software Architect at <strong><a href="/">Trilon</a></strong><br><br>

  Daniel is a tech-savvy open-source enthusiast software architect. Once an entrepreneur, he worked for some of the largest Brazilian unicorns, like QuintoAndar and Neon Bank. He created applications that handled thousands of requests per minute, and was a core contributor for Kubeless and other open-source libraries. Daniel uses NestJS since its early days and has been one of the evangelists of our framework in the Brazillian community.
  `,
  twitter: 'deluccadev',
  github: 'delucca',
};

const MANUEL_HERRERA = {
  name: 'Manuel Herrera',
  photo: `/assets/photos-v2/manuel-h.png`,
  role: 'Software Architect',
  bio: `
  Manuel Herrera is a Software Architect at <strong><a href="/">Trilon</a></strong><br><br>
  Manuel constantly strives to have a deeper understanding of technology. He has wide range of experience, from networking, fullstack development to architecture and engineering management. He has a passion for creating futureproof systems by first getting a strong understanding of a business' long-term strategy.  With a history of working in cyber security, he is constantly thinking about how best to secure applications from future potential attacks.
  `,
  // twitter: '',
  github: 'notusertelken',
};

const MANUEL_CARBALLIDO = {
  name: 'Manuel Carballido',
  photo: `/assets/photos-v2/manuel-2.png`,
  role: 'Senior Software Engineer',
  bio: `
  Manuel Carballido is a Senior Software Engineer at <strong><a href="/">Trilon</a></strong><br><br>
  Manuel is a tech enthusiast with years of experience as a full-stack developer.
  He has a very keen eye for detail and is a preacher of clean code as he puts a lot of attention
  into promoting and writing performant, scalable, and maintainable software.
  He is pragmatic and knows how to improve not only the code itself but the development process as a whole.
    `,
  // twitter: '',
  github: 'mcarballido',
};

const JAY_MCDONIEL = {
  hideAuthorSection: true,
  name: 'Jay McDoniel',
  photo: '/assets/photos-v2/jay.png',
  role: 'Software Architect',
  bio: `Jay McDoniel is a former Software Architect at <strong><a href="/">Trilon</a></strong><br><br>`,
};

const MACIEJ_SIKORSKI = {
  hideAuthorSection: true,
  name: 'Maciej Sikorski',
  photo: `/assets/photos-v2/maciej.png`,
  role: 'Software Architect',
  bio: `
  Maciej Sikorski is a former Software Architect at <strong><a href="/">Trilon</a></strong><br><br>`,
};

const THIAGO_MARTINS = {
  hideAuthorSection: true,
  name: 'Thiago Martins',
  photo: `/assets/photos-v2/thiago.png`,
  role: 'Senior Software Engineer',
  bio: `
  Thiago is a Senior Software Engineer at <strong><a href="/">Trilon</a></strong><br><br>

  He has years of experience as a fullstack developer and tech lead with various enterprise organizations and projects.
  With a passion and attention for detail, he is a evangelist for clean code, automated testing, and especially Design Patterns.
  Thiago always finds time to contribute to various open source projects, and is also an organizer of many different programming workshops.
  In his spare time he also loves working with artificial intelligence.
    `,
};

const ILYA_MOROZ = {
  hideAuthorSection: true,
  name: 'Ilya Moroz',
  firstName: 'Ilya',
  title: 'Senior Software Engineer',
  thumbnail: `/assets/photos-v2/ilya.png`,
  photo: `/assets/photos-v2/ilya.png`,
  bio: `
  Ilya Moroz is a Senior Software Engineer at <strong><a href="/">Trilon</a></strong><br><br>
  Ilya is a tech enthusiast with a broad spectrum of experience in different software engineering spheres, with the passion for  architecture & backend development.
A huge open source fan, Ilya supports various NestJS communities for NestJS in his spare time and is the creator of the nestjs-asyncapi package.
He is a pragmatic developer who loves improving Developer Experience, and building clean, maintainable, DRY code.
      `,
  videos: [],
};

const GUESTS = {
  WASSIM_CHEGHAM: {
    name: 'Wassim Chegham',
    photo: `/assets/photos/wassim_chegham.png`,
    role: 'Sr. Developer Advocate at Microsoft',
    bio: `
    ✨Building things <a href="https://twitter.com/microsoft" rel="nofollow">@Microsoft</a> with JavaScript<br>
    ✨<a href="https://twitter.com/angular" rel="nofollow">@angular</a> team<br>
    ✨Google Developer Expert for #AoGDevs #GCP<br>
    ✨<a href="http://xlayers.dev" rel="nofollow">xlayers.dev</a>
      • <a href="http://async-await.xyz" rel="nofollow">async-await.xyz</a>
      • <a href="http://autocap.cc" rel="nofollow">autocap.cc</a>
    `,
    twitter: 'manekinekko',
    github: 'manekinekko',
  },
  BRIAN_JOHNSON: {
    name: 'Brian Johnson',
    photo: `/assets/photos/brian_johnson.png`,
    role: 'Solutions Architect at FaneGene',
    bio: `
    Brian Johnson is the Solutions Architect & Technology Consultant for FanGene RS in Switzerland.  He has a passion for business analytics, process improvement, & software development and enjoys building solutions that enable companies to reach their operational excellence goals.  As a promoter of open source in the enterprise, Brian spends a lot of his free time evangelizing the tools and techniques used in his daily work—and has a particular affinity for NestJS!
    `,
    twitter: 'bjohnson_io',
  },
};

export const AUTHORS = {
  MARK_PIESZAK,
  KAMIL_MYSLIWIEC,
  MACIEJ_SIKORSKI,
  MICHAEL_HLADKY,
  THIAGO_MARTINS,
  ILYA_MOROZ,
  JAY_MCDONIEL,
  DANIEL_DE_LUCCA,
  MANUEL_HERRERA,
  MANUEL_CARBALLIDO,
  GUESTS,
};
