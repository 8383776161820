<template>
  <div class="container text-left mb-5">
    <div class="row justify-content-between">
      <div class="col-6 gray">© 2019-2025 Trilon.</div>

      <div class="col-6 text-right">
        <a href="#" v-scroll-to="'body'" class="cursor-pointer"
          >Back to Top
          <arrow />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow.vue';

export default {
  components: {
    Arrow,
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  color: #fff;
  &:hover {
    color: #e91e63; // [todo] use vars

    svg path {
      fill: #e91e63; // [todo] use vars
    }
  }
  .arrow-icon {
    transform: rotate(-90deg) scale(0.7);
    margin-left: 0.5rem;
  }
}
</style>
